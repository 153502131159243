import { useBreakpoint } from '@pretto/bricks/assets/utility'

import { Check, CheckProps } from '@pretto/website/src/templates/home/rows/heroPictureRate/components/Check/Check'
import { RateSectionProps } from '@pretto/website/src/templates/home/rows/heroPictureRate/components/RateSection/RateSection'

import * as S from './HeroPictureRate.styles'

export interface HeroPictureRateProps {
  checks: CheckProps[]
  ctaLabel: string
  ctaUrl: string
  image: string
  isSecondaryCtaDisplayed: boolean
  isTrustpilotDisplayed: boolean
  numberOfReviews: number
  rateSectionProps: RateSectionProps
  score: number
  secondaryCtaLabel?: string
  secondaryCtaUrl?: string
  text: string
  title: string
}

export const HeroPictureRate: React.FC<HeroPictureRateProps> = ({
  checks,
  ctaLabel,
  ctaUrl,
  image,
  isSecondaryCtaDisplayed,
  isTrustpilotDisplayed,
  numberOfReviews,
  rateSectionProps,
  score,
  secondaryCtaLabel,
  secondaryCtaUrl,
  text,
  title,
  ...props
}) => {
  const { isMobile } = useBreakpoint()
  const displaySecondaryCta = isSecondaryCtaDisplayed && secondaryCtaLabel && secondaryCtaUrl

  return (
    <S.HeroPictureRateContainer {...props}>
      <S.LeftSection>
        <S.Title>{title}</S.Title>

        <S.Text>{text}</S.Text>

        <S.CTAContainer>
          <S.CTA href={ctaUrl}>{ctaLabel}</S.CTA>

          {displaySecondaryCta && <S.SecondaryCTA href={secondaryCtaUrl}>{secondaryCtaLabel}</S.SecondaryCTA>}
        </S.CTAContainer>

        {checks.length > 0 && (
          <S.Checks>
            {checks.map(({ label }) => (
              <Check key={label} label={label} />
            ))}
          </S.Checks>
        )}

        {isTrustpilotDisplayed && <S.Trustpilot score={score} numberOfReviews={numberOfReviews} isShort={isMobile} />}
      </S.LeftSection>

      <S.RightSection>
        <S.Wrapper>
          <S.ImageWrapper>
            <S.Image path={image} />
          </S.ImageWrapper>

          <S.DarkFooter />

          <S.RateSection {...rateSectionProps} />
        </S.Wrapper>
      </S.RightSection>
    </S.HeroPictureRateContainer>
  )
}

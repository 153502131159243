import { g } from '@pretto/bricks/components/layout'

import { CheckBoldMini } from '@pretto/picto'

import styled from 'styled-components'

export interface CheckProps {
  label: string
}

export const Check: React.FC<CheckProps> = ({ label, ...props }) => (
  <CheckContainer {...props}>
    <CheckPicto />
    <Title>{label}</Title>
  </CheckContainer>
)

const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral1};
  gap: ${g(1)};
`

const CheckPicto = styled(CheckBoldMini)`
  height: ${g(2)};
  width: ${g(2)};
`

const Title = styled.div`
  ${({ theme }) => theme.typos.caption};
`

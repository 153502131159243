import { breakpoints, g, numeralBreakpoints } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import { PrimaryButtonFix } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'
import { SecondaryButton } from '@pretto/zen/reveal/atoms/buttons/secondaryButton/SecondaryButton'
import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'
import { TrustpilotRate } from '@pretto/zen/reveal/main/trustpilot/TrustpilotRate/TrustpilotRate'

import { RateSection as RateSectionComponent } from '@pretto/website/src/templates/home/rows/heroPictureRate/components/RateSection/RateSection'

import styled from 'styled-components'

export const HeroPictureRateContainer = styled.div`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.neutral4};
  padding-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: ${g(3)};
    padding-bottom: ${g(9)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: ${g(9)};
    padding-bottom: ${g(6)};
  }
`

export const LeftSection = styled.div`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
    margin-bottom: ${g(5)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 6])};
    justify-content: center;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 5])};
  }
`

export const Title = styled.h1`
  ${({ theme }) => theme.typos.heading3};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading2};
  }
`

export const Text = styled.div`
  ${({ theme }) => theme.typos.body4};
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(4)};
  }
`

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  margin-right: auto;
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    margin-top: ${g(4)};
  }
`

export const CTA = styled(PrimaryButtonFix)`
  width: fit-content;
`

export const SecondaryCTA = styled(SecondaryButton).attrs({ scheme: 'black' })``

export const Trustpilot = styled(TrustpilotRate)`
  margin-top: ${g(2)};
  height: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(4)};
    height: ${g(4)};
  }
`

export const Checks = styled.div`
  margin-top: ${g(2)};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${g(2)};
`

export const RightSection = styled.div`
  ${column([1, 6])};
  width: 100%;
  position: relative;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([9, 5])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([9, 4])};
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.neutral1};

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: unset;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    height: 100%;
  }
`

export const ImageWrapper = styled.div`
  display: block;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral1};
  overflow: hidden;
  max-height: ${g(30)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-top-left-radius: ${g(1)};
    border-top-right-radius: ${g(1)};
    max-height: ${g(34)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    height: 100%;
    max-height: ${g(51)};
  }
`

export const Image = styled(BaseImage).attrs({
  sizes: `(min-width: ${breakpoints.mobileL}) 735px, (min-height: ${breakpoints.tablet}) 700px, 392px)`,
  srcSetBreakpoints: [
    numeralBreakpoints.mobileS,
    numeralBreakpoints.mobileM,
    numeralBreakpoints.mobileL,
    numeralBreakpoints.tablet,
    numeralBreakpoints.laptop,
    numeralBreakpoints.desktop,
  ],
})`
  display: block;
  object-fit: cover;
  aspect-ratio: 375/216;
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    aspect-ratio: 516/272;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    aspect-ratio: 393/408;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 393/408;
    width: unset;
    height: 100%;
  }
`

export const DarkFooter = styled.div`
  height: ${g(14)};
  width: 100%;
  position: relative;

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: ${g(6)};
    border-bottom-left-radius: ${g(1)};
    border-bottom-right-radius: ${g(1)};
    background-color: ${({ theme }) => theme.colors.neutral1};

    &&::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 100%;
      height: ${g(8)};
      background: ${({ theme }) =>
        `linear-gradient(${addAlpha(theme.colors.neutral1, 0)}, ${addAlpha(theme.colors.neutral1, 1)})`};
    }
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    height: ${g(8)};

    &&::after {
      height: ${g(17)};
    }
  }
`

export const RateSection = styled(RateSectionComponent)`
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 0px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    position: absolute;
    right: ${g(3)};
    left: ${g(3)};
    bottom: ${g(-3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    left: ${g(-3)};
    top: ${g(42)};
    bottom: unset;
    z-index: 3;
  }
`

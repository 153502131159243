import temporal from '@pretto/bricks/core/utility/temporal'

import { Rate } from '@pretto/website/src/components/Rates/RatesTable/types/Rate.type'
import { CheckProps } from '@pretto/website/src/templates/home/rows/heroPictureRate/components/Check/Check'
import { HeroPictureRate } from '@pretto/website/src/templates/home/rows/heroPictureRate/components/HeroPictureRate/HeroPictureRate'
import { filterRatesByDurationProfilIncomeRegionMortgagors } from '@pretto/website/src/templates/home/rows/heroPictureRate/lib/filterRatesByDurationProfilIncomeRegionMortgagors'
import { useTrustScore } from '@pretto/website/src/utilities'

export interface HeroPictureRateContainerProps {
  checks: CheckProps[]
  ctaLabel: string
  ctaUrl: string
  duration: '120' | '180' | '240' | '300'
  image: string
  isSecondaryCtaDisplayed: boolean
  isTrustpilotDisplayed: boolean
  linkLabel: string
  linkUrl: string
  rates: Rate[]
  rateTitle?: string
  secondaryCtaLabel?: string
  secondaryCtaUrl?: string
  text: string
  title: string
}

export const HeroPictureRateContainer: React.FC<HeroPictureRateContainerProps> = ({
  checks,
  ctaLabel,
  ctaUrl,
  duration = '240',
  image,
  isSecondaryCtaDisplayed,
  isTrustpilotDisplayed,
  linkLabel,
  linkUrl,
  rates,
  rateTitle,
  secondaryCtaLabel,
  secondaryCtaUrl,
  text,
  title,
}) => {
  const { score, numberOfReviews } = useTrustScore()

  const { trend, rate } = filterRatesByDurationProfilIncomeRegionMortgagors({
    rates,
    duration: parseInt(duration),
  })

  const heroPictureRateContainerProps = {
    checks,
    ctaLabel,
    ctaUrl,
    image,
    isSecondaryCtaDisplayed,
    isTrustpilotDisplayed,
    numberOfReviews,
    rateSectionProps: {
      ctaLabel: linkLabel,
      ctaUrl: linkUrl,
      rate: rate.toLocaleString('fr', { maximumFractionDigits: 2 }),
      title: rateTitle ?? `Meilleur taux sur ${parseInt(duration) / 12} ans en ${temporal().format('MMMM YYYY')}`,
      trend,
    },
    score,
    secondaryCtaLabel,
    secondaryCtaUrl,
    text,
    title,
  }

  return <HeroPictureRate {...heroPictureRateContainerProps} />
}

import { breakpoints, g } from '@pretto/bricks/components/layout'
import Link from '@pretto/bricks/website/utility/Link'

import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'

import { ArrowDirectionDown, ArrowDirectionEgal, ArrowDirectionUp } from '@pretto/picto'
import { Trend } from '@pretto/website/src/templates/tauxImmobilier/types/trends'

import styled from 'styled-components'

const PICTO_TREND = {
  up: ArrowDirectionUp,
  down: ArrowDirectionDown,
  stable: ArrowDirectionEgal,
}

export interface RateSectionProps {
  ctaLabel: string
  ctaUrl: string
  rate: string
  title: string
  trend: Trend
}

export const RateSection: React.FC<RateSectionProps> = ({ ctaLabel, ctaUrl, rate, title, trend, ...props }) => (
  <RateSectionContainer {...props}>
    <Text>
      <Title>{title}</Title>
      <CTA href={ctaUrl}>{ctaLabel}</CTA>
    </Text>

    <RightSection>
      <Rate>{rate}</Rate>
      <Percent>%</Percent>
    </RightSection>

    <TrendPicto as={PICTO_TREND[trend]} />
  </RateSectionContainer>
)

const RateSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  position: relative;
  padding: ${g(3)} ${g(2, 4)};
  color: ${({ theme }) => theme.colors.white};
  backdrop-filter: blur(${g(1)});
  background-color: ${({ theme }) => addAlpha(theme.colors.neutral1, 0.4)};
  gap: ${g(2)};
  overflow: hidden;
  background: ${({ theme }) =>
    `linear-gradient(to bottom, ${addAlpha(theme.colors.neutral1, 0.4)} 0%, ${theme.colors.neutral1} 32px )`};

  @media screen and (min-width: ${breakpoints.tablet}) {
    background: unset;
    backdrop-filter: blur(${g(3)});
    background-color: ${({ theme }) => addAlpha(theme.colors.neutral1Disabled, 0.3)};
    border-radius: ${g(1)};
    align-items: center;
    padding: ${g(2, 4)} ${g(3)};
    gap: ${g(3)};
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

const Title = styled.div`
  ${({ theme }) => theme.typos.body4Bold};
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(1)};
  }
`

const CTA = styled(Link)`
  ${({ theme }) => theme.typos.captionUnderline};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: unset;
  }
`

const TrendPicto = styled.div`
  position: absolute;
  top: ${g(-1)};
  right: ${g(9)};
  height: ${g(12)};
  width: ${g(12)};
  color: ${({ theme }) => addAlpha(theme.colors.white, 0.2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    top: ${g(-3)};
    right: ${g(9)};
  }
`

const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${g(1)};
`

const Rate = styled.div`
  ${({ theme }) => theme.typos.body1};
  ${({ theme }) => theme.typos.isNumber};
`

const Percent = styled.div`
  ${({ theme }) => theme.typos.body3};
`

import { Rate } from '@pretto/website/src/components/Rates/RatesTable/types/Rate.type'

const SALARY_MIN = 40000
const SALARY_MAX = 80000
const MORTGAGORS = 1
const REGION = 'ile-de-france'

type FilterRatesByDurationProfilIncomeRegionMortgagorsProps = {
  rates: Rate[]
  duration: number
}

export const filterRatesByDurationProfilIncomeRegionMortgagors = ({
  rates,
  duration,
}: FilterRatesByDurationProfilIncomeRegionMortgagorsProps) => {
  const ratesFilteredByDurationProfilIncomeMortgagors = rates
    .filter(rate => rate.duration === duration)
    .filter(rate => rate.salaryMin === SALARY_MIN)
    .filter(rate => rate.salaryMax === SALARY_MAX)
    .filter(rate => rate.mortgagors === MORTGAGORS)
    .filter(rate => rate.region === REGION)

  return {
    trend: ratesFilteredByDurationProfilIncomeMortgagors[0].trend,
    rate: ratesFilteredByDurationProfilIncomeMortgagors[0].veryGoodRate,
  }
}
